import { createBifrostSubscription, BifrostSubscription } from "@ollie-sports/react-bifrost";
import { Team, AccountId, Org, CodeTeam, TeamId, CODE_TYPES, CodeOrg, OrgId } from "@ollie-sports/models";
import { getOwnTeamsQueryFragment } from "../query-fragments/team.fragments";
import _ from "lodash";
import * as express from "express";
import { validateToken } from "../internal-utils/server-auth";
import { getUniversalHelpers } from "../helpers";
import { generateOllieFirestoreV2 } from "@ollie-sports/firebase";
import { findUnusedCode } from "./common.api";
import {
  firestoreLiftDocsSubToBifrostSub,
  firestoreLiftQuerySubToBifrostSub
} from "../internal-utils/firestoreLiftSubToBifrostSub";

type OwnTeamsSub = {
  teams: Team[];
  orgs: Org[];
};

export function teams__client__teamsOrgSubscription(p: { selfAccountId: AccountId }): BifrostSubscription<OwnTeamsSub> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let instance = createBifrostSubscription<OwnTeamsSub>({
    dispose: () => {
      teamOrgsUnsubscribe();
      adminOrgsUnsubscribe();
      teamsUnsubscribe();
    }
  });

  const currData: { adminOrgs?: Org[]; teamOrgs?: Org[]; teams?: Team[] } = {};
  const maybeSendNext = () => {
    if (currData.adminOrgs && currData.teamOrgs && currData.teams) {
      const teams = _(currData.teams)
        .filter(t => !t.deletedAtMS)
        .sortBy(t => t.name)
        // Make sure they have a role (really just a workaround for bad data)
        .filter(
          t => !!(t.accounts && t.accounts[p.selfAccountId] && Object.keys((t.accounts as any)[p.selfAccountId].roles).length > 0)
        )
        .value();

      instance.nextData({
        teams,
        orgs: _(currData.adminOrgs.concat(currData.teamOrgs))
          .uniqBy(o => o.id)
          .value()
      });
    }
  };

  let teamOrgsUnsubscribe = () => {};
  const createTeamOrgsSub = (teams: Team[]) => {
    teamOrgsUnsubscribe();

    teamOrgsUnsubscribe = h.Org.docsSubscription(
      _(teams)
        .map(a => a.orgId)
        .compact()
        .uniq()
        .filter(oid => {
          return !currData.adminOrgs?.find(o => o.id === oid);
        })
        .value()
    ).subscribe(info => {
      currData.teamOrgs = info.filter((a): a is Org => !!a);
      maybeSendNext();
    }, console.error).unsubscribe;
  };

  const adminOrgsUnsubscribe = h.Org.querySubscription({
    where: [{ accounts: { [p.selfAccountId]: { exists: ["==", true] } } }]
  }).subscribe(info => {
    currData.adminOrgs = info.docs;
    maybeSendNext();
  }, console.error).unsubscribe;

  const teamsUnsubscribe = h.Team.querySubscription(getOwnTeamsQueryFragment({ accountId: p.selfAccountId })).subscribe(
    async info => {
      currData.teams = info.docs;
      createTeamOrgsSub(info.docs);
      maybeSendNext();
    },
    console.error
  ).unsubscribe;

  return instance;
}

export function teams__client__getActiveOrgTeamsSubscription(p: { orgId: OrgId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(
    h.Team.querySubscription({
      where: [{ orgId: ["==", p.orgId] }, { deletedAtMS: ["==", 0] }]
    })
  );
}

export function teams__client__getSettingsForMultipleTeamsSubscriptions(p: { teamIds: string[] }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftDocsSubToBifrostSub(h.TeamSettings.docsSubscription(p.teamIds));
}

export async function teams__server__getTeamsInOrg(p: { orgId: OrgId; selfAccountId: AccountId }): Promise<Team[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const [teamsInOrg, adminOrgs] = await Promise.all([
    h.Team.query({
      where: [
        { deletedAtMS: ["==", 0] },
        { accounts: { [p.selfAccountId]: { exists: ["==", true] } } },
        { orgId: ["==", p.orgId] }
      ],
      limit: 1
    }),
    h.Org.query({ where: [{ accounts: { [p.selfAccountId]: { exists: ["==", true] } } }, { id: ["==", p.orgId] }] })
  ]);

  if (!teamsInOrg.docs.length && !adminOrgs.docs.length) {
    throw { statusCode: 401, error: "Not authorized to view teams in this organization" };
  }

  let teamsQuery = await h.Team.query({ where: [{ deletedAtMS: ["==", 0] }, { orgId: ["==", p.orgId] }] });
  return teamsQuery.docs.filter(t => !t.deletedAtMS);
  // SERVER_ONLY_TOGGLE
}

teams__server__getTeamsInOrg.auth = async (r: express.Request) => {
  await validateToken(r);
};

export async function teams__server__getTeam(p: { teamId: TeamId }): Promise<Team | null> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  if (!p.teamId) {
    return null;
  }

  let team = await h.Team.getDoc(p.teamId);
  if (team && team.deletedAtMS && team.deletedAtMS > 0) return null;
  return team;
  // SERVER_ONLY_TOGGLE
}

teams__server__getTeam.auth = async (r: express.Request) => {
  await validateToken(r);
};

export function teams__client__getTeamSubscription(p: { teamId: string }): BifrostSubscription<Team> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const disposeFns: any[] = [];
  const instance = createBifrostSubscription<Team>({ dispose: () => disposeFns.forEach(fn => fn()) });

  if (!p.teamId) {
    throw new Error("No team id! teams__client__getTeamSubscription");
  }

  const sub = h.Team.docSubscription(p.teamId).subscribe(
    p2 => {
      if (p2) {
        instance.nextData(p2);
      }
    },
    e => {
      console.error(e.message);
    }
  );

  disposeFns.push(sub.unsubscribe);

  return instance;
}

export async function team__server__update(p: { teamPartial: Partial<Team> & { id: string } }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.Team.update({ id: p.teamPartial.id, doc: p.teamPartial });
  // SERVER_ONLY_TOGGLE
}

team__server__update.auth = async (r: express.Request) => {
  await validateToken(r);
};

export async function teams__server__getInviteCode(p: { teamId: TeamId; type: CODE_TYPES.joinTeam }): Promise<CodeTeam> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let code = await findUnusedCode();

  const codeObj: CodeTeam = {
    id: h.Code.generateId(),
    createdAtMS: Date.now(),
    teamId: p.teamId,
    codeType: p.type,
    expiryDateMS: getExpiryDate(),
    code: code
  };
  await h.Code.add({ doc: codeObj });

  return codeObj;
}

teams__server__getInviteCode.auth = async (r: express.Request) => {
  await validateToken(r);
};

export async function orgs__server__getInviteCode(p: { orgId: OrgId }): Promise<CodeOrg> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let code = await findUnusedCode();

  const codeObj: CodeOrg = {
    id: h.Code.generateId(),
    orgId: p.orgId,
    codeType: CODE_TYPES.joinOrgAsStaff,
    expiryDateMS: getExpiryDate(),
    createdAtMS: Date.now(),
    code: code
  };
  await h.Code.add({ doc: codeObj });
  return Promise.resolve(codeObj);
  // SERVER_ONLY_TOGGLE
}

orgs__server__getInviteCode.auth = async (r: express.Request) => {
  await validateToken(r);
};

export function getExpiryDate(): number {
  const MONTH = 1000 * 60 * 60 * 24 * 365;
  const date = new Date();
  return date.getTime() + MONTH;
}

// i18n certified - complete
